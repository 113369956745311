import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid, Typography, Button, Paper } from "@material-ui/core";
import { Link, useStaticQuery } from "gatsby";
import { LIVE_URL, PICTURE_STRAPI_API_URL } from "../../utils/constants";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import desktopWallpaperImg from "../../assets/img/1920x1080/desktop-wallpaper.png";
import breathworkImg from "../../assets/img/1920x1080/breathwork.png";
import consciousLivingImg from "../../assets/img/1920x1080/CONSCIOUS-LIVING.png";
import meditationImg from "../../assets/img/1920x1080/meditation.png";
import masterclassImg from "../../assets/img/1920x1080/masterclass.png";
import yoga from "../../assets/img/1920x1080/yoga.png";
import { StaticQuery, graphql } from "gatsby";
import BackgroundSlider from "react-background-slider";
import "./index.css";
var intervals = "";

function HomeSection1({ title, image, baseLine, description, animatedText }) {
  const classes = useStyles();
  const theme = useTheme();
  const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
  const [imagesArray, setImagesArray] = useState([]);
  const [itemArray, setItemsArray] = useState([]);
  var txtIndex = 0;

  const data = useStaticQuery(graphql`
    query HomeQuery {
      allStrapiHomeCarousels(sort: { fields: order }) {
        nodes {
          desktopImage {
            publicURL
            childImageSharp {
              fluid(webpQuality: 50) {
                srcWebp
                srcSetWebp
              }
            }
          }
          order
          title
        }
      }
    }
  `);

  function intervalFunction(itm) {
    let intervals1 = setTimeout(function () {
      txtIndex = txtIndex + 1 < itm.length ? txtIndex + 1 : 0;

      var div = document.getElementById("text1");
      div.innerHTML = itm[txtIndex];
      clearInterval(intervals1);
      intervalFunction(itm);
    }, 4100);
  }

  useEffect(() => {
    try {
      let att = [];
      let itm = [];

      if (data?.allStrapiHomeCarousels?.nodes?.length > 0) {
        data.allStrapiHomeCarousels.nodes.forEach((step, index) => {
          console.log(
            "carousal image " +
              step?.desktopImage?.childImageSharp?.fluid?.srcSetWebp
          );
          // att.push(`${step?.desktopImage?.childImageSharp?.fluid?.srcWebp}`);
          att.push(`${step?.desktopImage?.publicURL}`);

          itm.push(step?.title);
          // alert("hhhhf");
        });
      }
      setItemsArray([...itm]);

      var div = document.getElementById("text1");
      div.innerHTML = itm[txtIndex];
      intervalFunction(itm);

      // console.log("intervals", intervals);
      setImagesArray([...att]);
    } catch (e) {}
  }, [data]);
  useEffect(() => {
    return () => clearInterval(intervals);
  }, []);

  // debugger;
  return (
    <Grid container>
      {/* <Grid
        item
        xs={12}
        style={{
          paddingTop: "56.25%",
          position: "relative",
          width: "100%",
          display: "flex",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          height: 0,
        }}
      > */}
      <div
        className={classes.root}
        style={{ width: "100vw", position: "relative" }}
      >
        <Grid xs={6}> </Grid>
        <Grid
          container
          xs={6}
          style={{
            display: "flex",
            position: "absolute",
            justifyContent: "center",
            right: 30,
            top: "auto",
          }}
        >
          <Grid
            item
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-end",
            }}
          >
            <Grid item xs={12} style={{ zIndex: 10 }}>
              <Grid item xs={12} className={classes.centerContainer}>
                <Typography variant="h2" className={classes.heading}>
                  {title}
                </Typography>
                <div class="container">
                  <div id="move">
                    <ul>
                      <h1 id="text1" className={classes.items}>
                        {/* {itemArray.length > 0 && itemArray[txtIndex]} */}
                      </h1>
                    </ul>
                  </div>
                </div>
              </Grid>
              {/* <Grid item xs={12} className={classes.centerContainer}>
                      <div
                        className="text-container"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        <ul className="dynamic-text">
                          {itemArray &&
                            itemArray.map((item, index) => (
                              <li key={index} className={classes.items}>
                                {item}
                              </li>
                            ))}
                        </ul>
                      </div>
                    </Grid> */}
              <Grid
                item
                xs={9}
                className={classes.centerContainer}
                style={{ marginTop: "60px" }}
              >
                <Typography variant="h2" className={classes.subHeading}>
                  {baseLine}
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                className={classes.centerContainer}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Grid item style={{ marginRight: "10px" }}>
                  <Link
                    to={`${LIVE_URL}auth?tab=signup`}
                    style={{ textDecoration: "none" }}
                    // target="_blank"
                  >
                    <Button
                      aria-label="Lets Start"
                      variant="contained"
                      className={classes.letsStartBtn}
                    >
                      Lets Start
                    </Button>
                  </Link>
                </Grid>

                <Grid item>
                  <Link
                    to={`${LIVE_URL}auth`}
                    style={{ textDecoration: "none" }}
                    // target="_blank"
                  >
                    <Button
                      aria-label="Sign In"
                      variant="contained"
                      className={classes.signInBtn}
                    >
                      Sign In
                    </Button>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <BackgroundSlider
          // images={[consciousLivingImg, meditationImg]}
          images={imagesArray}
          duration={4}
          transition={0}
          // style={{ postion: "absolute" }}
        />
      </div>
      {/* </Grid> */}
    </Grid>
  );
}

export default HomeSection1;

const useStyles = makeStyles((theme) => ({
  items: {
    position: "relative",
    top: 0,
    /*  animation: "move 5s ease-in-out infinite alternate", */
    animation: "move 15s linear infinite 1s",
    animationDuration: "15s",
    animationTimingFunction: "ease-in-out",
    animationDelay: "0",
    animationIterationCount: "infinite",
    animationDirection: "normal",
    animationFillMode: "none",
    animationPlayState: "running",
    fontFamily: theme.palette.fontFamily.Reef,
    fontStyle: "normal",
    // fontWeight: "900",
    fontWeight: "bold !important",

    fontSize: "36px",
    lineHeight: "49px",
    textAlign: "center",
    letterSpacing: " 0.02em",
    // color: "#FFFFFF",
    color: "#FF6035",
    marginTop: "15px",
    marginBottom: "20px",
  },
  root: {
    flexGrow: 1,
    // backgroundImage: `url(${backgroundImage})`,
    // background: `#C4C4C4`,
    minHeight: "100vh",
    height: "100%",

    // backgroundPosition: 'top center'
  },
  topRightCircle: {
    width: "150px",
    height: "150px",
    borderRadius: "2% 0px 2% 100%",
    float: "right",
    backgroundColor: theme.palette.secondary.light,
    opacity: "0.5",
  },
  bottomLeftCircle: {
    width: "50px",
    height: "50px",
    borderRadius: "50%",
    float: "right",
    backgroundColor: "#14D9D9",
    opacity: "0.5",
  },
  centerContainer: {
    textAlign: "center",
    margin: "auto",
    fontWeight: "bold",
  },
  heading: {
    fontFamily: theme.palette.fontFamily.Reef,
    marginTop: "0px",
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "30px",
    lineHeight: "65px",
    textAlign: "center",
    letterSpacing: " 0.02em",
    //color: "#FFFFFF",
    color: "black",
    marginTop: "150px",
  },
  subHeading: {
    fontFamily: theme.palette.fontFamily.Reef,
    // marginTop: "30px",
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "28px",
    // lineHeight: "49px",
    textAlign: "center",
    letterSpacing: " 0.04em",
    //  color: "#FFFFFF",
    color: "black",
    paddingLeft: "6px",
    paddingRight: "6px",
    textTransform: "uppercase",
  },
  letsStartBtn: {
    textTransform: "none",
    width: "200px",

    // background: theme.palette.secondary.light,

    borderRadius: "59px",
    fontFamily: theme.palette.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "24px",
    // lineHeight: "33px",
    // letterSpacing: " 0.09em",
    color: "#FFFFFF",
    marginTop: "35px",
    transition: "background 1s",
    background: theme.palette.secondary.light,
    border: "none",
    "&:hover": {
      background: theme.palette.secondary.light,
      border: "none",
    },
  },

  signInBtn: {
    textTransform: "none",
    width: "200px",

    borderRadius: "59px",
    fontFamily: theme.palette.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "24px",
    // lineHeight: "33px",
    // letterSpacing: " 0.09em",
    // color: "#FFFFFF",
    color: "#FF6035",
    marginTop: "35px",
    transition: "background 1s",
    //background: "transparent",
    background: "#F3F3F3",

    // border: "2px solid #FFFFFF",
    border: "2px solid #FF6035",

    "&:hover": {
      background: theme.palette.secondary.light,
      border: "2px solid transparent",
      color: "white",
    },
  },
  // slider ..
  header: {
    display: "flex",
    alignItems: "center",
    // height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
  },
  img1: {
    height: 655,
    display: "block",
    // maxWidth: 400,
    overflow: "hidden",
    width: "100%",
  },
}));
