import React from "react";
import { Button, useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";

function CustomButton(props) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Button
      className={classes.btn}
      onClick={props?.onClick}
      aria-label="Free"
      style={
        props?.marginTop
          ? {
              marginTop: props?.marginTop,
              height: props?.height,
              fontSize: isMobile && "15px",
            }
          : {
              height: props?.height,
              fontSize: isMobile && "15px",
            }
      }
    >
      {props.btnText}
    </Button>
  );
}

export default CustomButton;

const useStyles = makeStyles((theme) => ({
  btn: {
    background: theme.palette.secondary.light,
    borderRadius: "7px",
    fontFamily: theme.palette.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "20px",
    width: "100%",
    height: "81px",
    lineHeight: "28px",
    color: "#FBFBFF",
    textTransform: "none",
    marginTop: "50px",
    "&:hover": {
      background: theme.palette.secondary.dark,
    },
  },
}));
