import React, { useEffect, useState } from "react";
import Layout from "../components/layout/index";
import HomeSection1 from "../components/home/newSection";
import MainHomeSection from "../components/home/mainHomeSection";
import MobileView from "../components/home/mobileMainHomeSection";
import OMidTv from "../components/home/omidTv";
import { graphql } from "gatsby";
import useFetch from "use-http";
import { RELATE_APP_API_URL } from "../utils/constants";
import { useLocation } from "@reach/router";
import CoursesTabs from "../components/courses/coursesTabs";
import ChallengesTab from "../components/challenges";
import InstructorsTabs from "../components/courses/instructorTabs";
import Carousel from "../components/courses/carousel";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";
import { Search, Close } from "@material-ui/icons";
import * as JsSearch from "js-search";
import CustomButton from "../components/common/button";

import {
  Grid,
  TextField,
  Divider,
  Typography,
  useMediaQuery,
} from "@material-ui/core";

export default function Home({ data }) {
  console.log("data", data);
  /* console.log(data?.allRestApiApiV1StaticGetInstructorsAndStudios?.edges[0]?.node?.data?.instructors,"instructors") */

  const seoData = {
    title: data?.strapiHomeMetaData?.title,
    description: data?.strapiHomeMetaData?.description,
    image: data?.strapiHomeMetaData?.image?.publicURL,
    keyword: data?.strapiHomeMetaData?.keyword,
  };

  const { get, response, cache } = useFetch(`${RELATE_APP_API_URL}`);
  const [configs, setConfigs] = useState(null);
  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const location = useLocation();

  const [categories, setCategories] = useState(null);
  const [filteredCategories, setFilteredCategories] = useState(null);
  const [instructors, setInstructors] = useState(null);
  const [challenges, setChallenges] = useState([]);
  const [filteredInstructors, setFilteredInstructors] = useState(null);
  const [filteredData, setFilteredData] = useState("");
  const [allData, setAllData] = useState("");

  const [loading, setLoading] = useState(false);
  var search = new JsSearch.Search("title");
  search.addIndex("description");
  search.addIndex("title");

  const handleSearch = (e) => {
    if (e.target.value !== "") {
      let result = search.search(e.target.value);
      setFilteredData(result);
    } else {
      setFilteredData(allData);
    }
  };
  /*   useEffect(() => {
    if (data) {
      let array = [];
      let obj = data?.allRestApiApiV1StaticCoursesGroupByCategory?.edges[0]?.node?.data?.map((item) => {
        return array.push(item.node);
      });
      setFilteredData(array);
      setAllData(array);
    }
  }, [data]); */
  useEffect(() => {
    window.scrollTo(0, 0);

    async function fetchConfigs() {
      const _configs = data?.allRestApiApiV1Config?.edges[0]?.node;

      let obj = {};
      for (const item of _configs?.data) {
        obj = { ...obj, [item.key]: item.value };
      }

      setConfigs(obj);
      setLoading(false);
    }

    fetchConfigs();

    if (location?.state?.scroll) {
      scrollToDiv();
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [data]);
  const scrollToDiv = () => {
    var element = document.getElementById("download-app-div");
    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  };
  const scrollToHomeDiv = () => {
    var element = document.getElementById("home-div");
    element.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "nearest",
    });
  };

  useEffect(() => {
    async function fetchCategories() {
      /*  const _categories = await get("/static/coursesGroupByCategory");
      if (response.ok) {
        setCategories(_categories?.data);
      } else {
        fetchCategories();
      } */
      if (
        data?.allRestApiApiV1StaticCoursesGroupByCategory?.edges[0]?.node?.data
      ) {
        setCategories(
          data?.allRestApiApiV1StaticCoursesGroupByCategory?.edges[0]?.node
            ?.data
        );
      } else {
        fetchCategories();
      }
    }
    async function fetchInstructors() {
      /*  const _instructors = await get("/static/getInstructorsAndStudios");
      if (response.ok) {
        setInstructors(_instructors?.data);
      } else {
        fetchInstructors();
      } */

      if (
        data?.allRestApiApiV1StaticGetInstructorsAndStudios?.edges[0]?.node
          ?.data?.instructors
      ) {
        setInstructors(
          data?.allRestApiApiV1StaticGetInstructorsAndStudios?.edges[0]?.node
            ?.data?.instructors
        );
      } else {
        fetchInstructors();
      }
    }
    async function fetchChallenges() {
      /*   const _challenges = await get("/static/getAllChallenges");

      if (response.ok) {
        setChallenges(_challenges?.data);
      } else {
        fetchChallenges();
      } */

      if (data?.allRestApiApiV1StaticGetAllChallenges?.edges[0]?.node?.data) {
        setChallenges(
          data?.allRestApiApiV1StaticGetAllChallenges?.edges[0]?.node?.data
        );
      } else {
        fetchChallenges();
      }
    }

    fetchChallenges();
    fetchCategories();
    fetchInstructors();
  }, []);

  useEffect(() => {
    if (categories) {
      setFilteredCategories(categories);
    }
  }, [categories]);
  useEffect(() => {
    if (instructors) {
      setFilteredInstructors(instructors);
    }
  }, [instructors]);

  useEffect(() => {
    if (location?.state?.scroll) {
      setTimeout(() => {
        scrollToTeachersDiv();
      }, 500);
    }
  }, []);

  const scrollToTeachersDiv = () => {
    var element = document.getElementById("teachers-div");
    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "center",
    });
  };
  const scrollToCoursesDiv = () => {
    var element = document.getElementById("courses-div");
    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  };

  const dividerComponent = (
    <Grid item xs={11} md={6} container>
      <Grid
        container
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Grid item xs={4} md={5}>
          <Divider className={classes.divider} />
        </Grid>
        <Grid
          item
          xs={4}
          md={2}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={"/img/Vector.png"} alt="img" loading="lazy" />
        </Grid>
        <Grid item xs={4} md={5}>
          <Divider className={classes.divider} />
        </Grid>
      </Grid>
    </Grid>
  );
  const isMobile520 = useMediaQuery(`(min-width:481px)`);

  const isMobile480 = useMediaQuery(`(width:480px)`);
  const isMobile411 = useMediaQuery(`(min-width:411px)`);
  const isMobile259 = useMediaQuery(`(max-width:281px)`);
  const isMobile360 = useMediaQuery(`(min-width:360px)`);

  return (
    <Layout
      seoData={seoData}
      scrollToDiv={scrollToDiv}
      scrollToHomeDiv={scrollToHomeDiv}
    >
      <Grid
        container
        style={{ backgroundColor: isMobile ? "#FFF" : "#FBFBFB" }}
      >
        {/* <Grid item xs={12}>
          <HeroImageSection data={data?.strapiHomeSection1} />
        </Grid> */}

        <Grid
          item
          xs={12}
          id="home-div"
          style={isMobile ? { minHeight: "390px" } : {}}
        >
          {!isMobile && isDesktop ? (
            <MainHomeSection
              data={data?.strapiHomeSection1}
              title={configs?.landingTitle}
              image={configs?.landingBackground}
              baseLine={configs?.landingBaseline}
              animatedText={configs?.animatedText?.split(",")}
              // description={"Online Courses, live sessions, Online Retreats, Online Teacher traning & various challenges, carefully curated with international best teachers"}
              description={configs?.landingDescription}
            />
          ) : (
            <MobileView
              data={data?.strapiHomeSection1}
              title={configs?.landingTitle}
              image={configs?.landingBackground}
              baseLine={configs?.landingBaseline}
              animatedText={configs?.animatedText?.split(",")}
              description={configs?.landingDescription}

              // description={"Online Courses, live sessions, Online Retreats, Online Teacher traning & various challenges, carefully curated with international best teachers"}
            />
          )}
        </Grid>

        {/* <Grid item xs={12} container  style={{ display: "flex", justifyContent: "center", background: isMobile && '#FFF' }}>
          <Subscriptions />
        </Grid> */}

        {/* <Grid item xs={12}>
          <HomeSection2 data={data?.allStrapiFeatures} />
        </Grid> */}

        <Grid
          container
          id={"courses-div"}
          style={{ display: "flex", justifyContent: "center" }}
        >
          {/* <Grid item xs={11} md={6}>
            <Grid
              container
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Grid item xs={4} md={5}>
                <Divider className={classes.divider} />
              </Grid>
              <Grid
                item
                xs={4}
                md={2}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src={"/img/Vector.png"} alt="img" />
              </Grid>
              <Grid item xs={4} md={5}>
                <Divider className={classes.divider} />
              </Grid>
            </Grid>
          </Grid> */}

          <Grid container style={{ marginTop: "2%" }}>
            <Typography
              variant="h2"
              style={{ fontSize: isMobile && "24px" }}
              className={classes.heading}
            >
              {data?.strapiCoursesPageInfo?.coursesTitle}
            </Typography>
          </Grid>
          <Grid
            container
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "1%",
            }}
          >
            <Grid
              item
              xs={12}
              md={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: isMobile ? "0px 5px" : "0px 30px",
              }}
            >
              <Typography
                variant="h2"
                style={{ fontSize: isMobile && "18px" }}
                className={classes.subHeading}
              >
                {data?.strapiCoursesPageInfo?.aboutCourses}
              </Typography>
            </Grid>
          </Grid>

          {/*    <Grid container justify="center">
            <Grid
              item
              xs={12}
              xs={12}
              md={6}
              container
              justify="center"
              spacing={isMobile ? 1 : 2}
              style={{
                padding: isMobile ? "20px 5px" : "20px 30px",
              }}
            >

                <>
                  <Grid item xs={8} sm={8} md={9} lg={9}>
                    <TextField
                      className={classes.searchField}
                      type="Search"
                      style={{ width: "100%" }}
                      placeholder="Search..."
                      name="search"
                      onChange={handleSearch}
                      InputProps={{
                        endAdornment: <Search />,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sm={4}
                    md={2}
                    lg={2}
                    style={{ position: "relative" }}
                  >
                    <div style={{ maxWidth: "95%" }}>
                      <CustomButton
                        btnText="Search"
                        height="58px"
                        marginTop="20px"
                      />
                    </div>

                  </Grid>
                </>

            </Grid>
          </Grid>*/}

          <Grid container style={{ marginTop: "3%" }}>
            {data?.allRestApiApiV1StaticCoursesGroupByCategory?.edges[0]?.node
              ?.data.length && (
              <CoursesTabs
                categories={
                  data?.allRestApiApiV1StaticCoursesGroupByCategory?.edges[0]
                    ?.node?.data
                }
                isHome={true}
                configs={configs}
              />
            )}
          </Grid>
        </Grid>
        <Grid
          container
          id={"courses-div"}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Grid item xs={11} md={6}>
            <Grid
              container
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Grid item xs={4} md={5}>
                <Divider className={classes.divider} />
              </Grid>
              <Grid
                item
                xs={4}
                md={2}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src={"/img/Vector.png"}
                  alt="img"
                  width="81"
                  height="75"
                  loading="lazy"
                />
              </Grid>
              <Grid item xs={4} md={5}>
                <Divider className={classes.divider} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          id={"courses-div"}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Grid container style={{ marginTop: "2%" }}>
            <Typography
              variant="h2"
              style={{ fontSize: isMobile && "24px" }}
              className={classes.heading}
            >
              {data?.strapiCoursesPageInfo?.coursesTitle}
            </Typography>
          </Grid>
          <Grid
            container
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "1%",
            }}
          >
            <Grid
              item
              xs={12}
              md={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: isMobile ? "0px 5px" : "0px 30px",
              }}
            >
              <Typography
                variant="h2"
                style={{ fontSize: isMobile && "18px" }}
                className={classes.challengeHeading}
              >
                Challenges
                {/*    {data?.strapiCoursesPageInfo?.aboutCourses} */}
              </Typography>
            </Grid>
          </Grid>

          <Grid container style={{ marginTop: "3%" }}>
            {data?.allRestApiApiV1StaticGetAllChallenges?.edges[0]?.node?.data
              ?.length && (
              <ChallengesTab
                challenges={
                  data?.allRestApiApiV1StaticGetAllChallenges?.edges[0]?.node
                    ?.data
                }
                isHome={true}
                configs={configs}
              />
            )}
          </Grid>
        </Grid>

        <Grid
          container
          id={"teachers-div"}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Grid item xs={11} md={6}>
            <Grid
              container
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Grid item xs={4} md={5}>
                <Divider className={classes.divider} />
              </Grid>
              <Grid
                item
                xs={4}
                md={2}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src={"/img/Vector.png"}
                  alt="img"
                  width="81"
                  height="75"
                  loading="lazy"
                />
              </Grid>
              <Grid item xs={4} md={5}>
                <Divider className={classes.divider} />
              </Grid>
            </Grid>
          </Grid>

          <Grid container style={{ marginTop: "2%" }}>
            <Typography
              variant="h2"
              style={{ fontSize: isMobile && "24px" }}
              className={classes.heading}
            >
              {data?.strapiCoursesPageInfo?.instructorsTitle}
            </Typography>
          </Grid>

          <Grid
            container
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "2%",
            }}
          >
            <Grid
              item
              xs={12}
              md={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: isMobile ? "0px 5px" : "0px 30px",
              }}
            >
              <Typography
                variant="h2"
                style={{ fontSize: isMobile && "18px" }}
                className={classes.subHeading}
              >
                {data?.strapiCoursesPageInfo?.aboutInstructors}
              </Typography>
            </Grid>
            {data?.strapiHomeInstructorSection?.description1 && (
              <Grid
                item
                xs={12}
                md={12}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: isMobile ? "0px 5px" : "0px 30px",
                }}
              >
                <Typography
                  variant="h2"
                  style={{ fontSize: isMobile && "18px" }}
                  className={classes.subHeading}
                >
                  {data?.strapiHomeInstructorSection?.description1}
                </Typography>
              </Grid>
            )}
          </Grid>

          <Grid container style={{ marginTop: "0%" }}>
            {data?.allRestApiApiV1StaticGetInstructorsAndStudios?.edges[0]?.node
              ?.data?.instructors && (
              <InstructorsTabs
                instructors={
                  data?.allRestApiApiV1StaticGetInstructorsAndStudios?.edges[0]
                    ?.node?.data?.instructors
                }
                studios={filteredInstructors?.studios}
                categories={categories}
              />
            )}
          </Grid>
        </Grid>

        {/* <Grid item xs={12}>
          <OMidTv data={data?.strapiSiteInfo} />
        </Grid> */}
        <Grid
          item
          xs={12}
          container
          id={"courses-div"}
          style={{
            display: "flex",
            justifyContent: "center",
            background: isMobile && "#FFF",
          }}
        >
          <Grid item xs={11} md={6} container>
            <Grid
              container
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Grid item xs={4} md={5}>
                <Divider className={classes.divider} />
              </Grid>
              <Grid
                item
                xs={4}
                md={2}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src={"/img/Vector.png"}
                  alt="img"
                  width="81"
                  height="75"
                  loading="lazy"
                />
              </Grid>
              <Grid item xs={4} md={5}>
                <Divider className={classes.divider} />
              </Grid>
            </Grid>
          </Grid>

          <HomeSection1
            data={data?.allStrapiFeatures}
            strapiSiteIntro={data?.strapiSiteIntro}
            strapiSiteInfo={data?.strapiSiteInfo}
          />
        </Grid>
        <Grid item xs={12}>
          <OMidTv data={data?.strapiSiteInfo} />
        </Grid>
        {data?.strapiHomeTestimonial?.description && (
          <Grid
            item
            xs={12}
            md={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: isMobile ? "0px 5px" : "0px 30px",
              // marginBottom: isMobile ? " 5px" : "30px",
              backgroundColor: "rgb(245, 245, 245)",
              // backgroundColor: "rgb(251, 251, 251)",
            }}
          >
            <Typography
              variant="h2"
              style={{ fontSize: isMobile && "18px" }}
              className={classes.subHeading}
            >
              {data?.strapiHomeTestimonial?.description}
            </Typography>
          </Grid>
        )}
        <Grid container>
          <Carousel />
        </Grid>
      </Grid>
    </Layout>
  );
}

const useStyles = makeStyles((theme) => ({
  divider: {
    background: theme.palette.primary.light,
    height: "2px",
  },
  heading: {
    textAlign: "center",
    width: "100%",
    fontFamily: theme.palette.fontFamily.Reef,
    fontWeight: 900,
    fontSize: "32px",
    lineHeight: "42.19px",

    fontStyle: "normal",
    color: "#000",
  },
  challengeHeading: {
    textAlign: "center",
    width: "100%",
    fontFamily: theme.palette.fontFamily.Reef,
    fontWeight: 900,
    fontSize: "32px",
    lineHeight: "42.19px",
    fontStyle: "normal",
    textTransform: "uppercase",
    color: "#000",
  },
  subHeading: {
    textAlign: "center",
    width: "100%",
    fontFamily: theme.palette.fontFamily.Reef,
    fontWeight: 500,
    fontSize: "22px",
    lineHeight: "36.41px",
    color: "#3F3F3F",

    fontStyle: "normal",
  },
  searchField: {
    "& .MuiInputBase-root": {
      backgroundColor: "rgba(9, 169, 187, 0.1)",
      color: "rgba(124, 124, 124, 0.25)",
      fontFamily: theme.palette.fontFamily.Reef,
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "18px",
      lineHeight: "21px",
      padding: "10px 30px",
      height: "58px",
      alignSelf: "center",
      position: "static",
      marginBottom: "20px",
      width: "100%",
      marginTop: "20px",
      borderRadius: "10px",
      boxShadow: "inset 0px 6px 6px rgba(0, 65, 72, 0.05)",
    },
    "& .MuiBadge-colorPrimary": {
      backgroundColor: theme.palette.secondary.light,
    },

    "& .MuiInput-underline:before": {
      border: "0px solid #FBF1E0",
    },
    "& .MuiInput-underline::after": {
      border: "0px solid #FBF1E0",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled)::before": {
      border: "0px solid #FBF1E0",
    },
  },
}));

export const query = graphql`
  {
    allStrapiFeatures {
      edges {
        node {
          description
          title
        }
      }
    }
    strapiSiteIntro {
      Title
      subtitile
    }
    strapiCoursesPageInfo {
      aboutCourses
      aboutInstructors
      instructorsTitle
      coursesTitle
    }
    strapiSiteInfo {
      omidTvTitle
      omidTvDescription
      featureHeading
      featureDescription
    }
    strapiHomeInstructorSection {
      description1
    }
    strapiHomeTestimonial {
      description
    }
    allRestApiApiV1Config {
      edges {
        node {
          data {
            label
            value
            createdAt
            id
            key
            updatedAt
          }
        }
      }
    }
    allRestApiApiV1StaticCoursesGroupByCategory {
      edges {
        node {
          data {
            name
            id
            order
            courses {
              categoryId
              slug
              seoKeywords
              seoDescription
              remarks
              price
              ipfsCid
              previewUrl
              planId
              seoTitle
              image
              isPrivate
              name
              id
              plan {
                createdAt
                description
                id
                name
                price
                type
              }
              episodes {
                order
                description
                duration
                id
                name
              }
              category {
                id
                name
                order
                thumbnail
              }
              user {
                description
                id
                name
                pictureUrl
              }
            }
          }
        }
      }
    }

    allRestApiApiV1StaticGetAllChallenges {
      edges {
        node {
          data {
            description
            id
            imageUrl
            order
            plan
            price
            title
          }
        }
      }
    }
    allRestApiApiV1StaticGetInstructorsAndStudios {
      edges {
        node {
          data {
            instructors {
              courses {
                categoryId
                id
                image
                name
                remarks
                seoDescription
                seoKeywords
                seoTitle
                slug
              }
              id
              name
              lastName
              pictureUrl
              picturePath
              title
              username
              firstName
              role
              status
              coverUrl
              description
              email
              emailConfirmed

              facebookUrl
              coverPath
              websiteUrl
              youtubeChannelUrl

              instagramUrl

              city
            }
          }
          id
        }
      }
    }
  }
`;
