import React from "react";
import {
  Card,
  Grid,
  Tooltip,
  CardContent,
  Typography,
  IconButton,
  LinearProgress,
  Box,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { PlayArrow } from "@material-ui/icons";
import Moment from "react-moment";
import TextTruncate from "react-text-truncate";

function LinearProgressWithLabel(props) {
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress
          variant="determinate"
          {...props}
          color="primary"
          classes={{
            colorPrimary: classes.bar,
            barColorPrimary: classes.progressBar,
          }}
        />
      </Box>
    </Box>
  );
}

function LiveEventCard(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { data } = props;
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [progress, setProgress] = React.useState(70);

  return (
    <Card className={classes.cardContainer}>
      <CardContent
        style={{ backgroundColor: "#FFF", padding: isMobile && "10px 5px" }}
      >
        <Grid container spacing={2}>
          <Grid item xs={5} sm={3} md={3} lg={3} xl={3}>
            <div
              style={{
                background: `url(${data?.Thumbnail?.childImageSharp.fluid.srcWebp}), #C4C4C4`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
              className={classes.imageContainer}
            >
              {props?.id === data?.id && (
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <IconButton
                    aria-label="Preview"
                    size="small"
                    style={{ background: "#09A9BB" }}
                  >
                    {""}
                    <PlayArrow />
                  </IconButton>
                </div>
              )}
            </div>
          </Grid>
          <Grid container item xs={7} sm={9} md={9} lg={9} xl={9}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography
                variant="h6"
                component="h6"
                gutterBottom
                className={[classes.textStyle, classes.headingFont]}
                style={{ fontSize: "16px" }}
              >
                <Tooltip title={data?.Title}>
                  <TextTruncate
                    line={1}
                    truncateText="..."
                    text={props?.ind + ". " + data?.Title}
                  />
                  {/* {props?.ind + '. ' + data?.Title}
                                    </TextTruncate> */}
                </Tooltip>
              </Typography>
            </Grid>
            {/* <Grid item xs={3} sm={3} md={2}>
                            <Typography className={[classes.textStyle, classes.text]} >
                                {'20 mins'}
                            </Typography>
                        </Grid> */}

            {/* <Grid item xs={12}>
                            <LinearProgressWithLabel value={progress} />
                        </Grid> */}

            <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
              <Typography
                variant="h6"
                component="h6"
                gutterBottom
                className={[classes.textStyle, classes.subHeadingFont]}
                style={{ fontSize: "14px" }}
              >
                <TextTruncate
                  line={1}
                  truncateText="..."
                  text={data?.Description}
                />
                {/* {data?.Description + 'dsfsa fjskljfskjfs fs flsj flkjsl fsl fjs fls'}
                                </TextTruncate> */}
              </Typography>
            </Grid>
            {!isMobile && (
              <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                <Typography className={[classes.textStyle, classes.text]}>
                  <Moment format="DD, MMM YY">{data?.created_at}</Moment>
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default LiveEventCard;

const useStyles = makeStyles((theme) => ({
  imageContainer: {
    height: 0,
    paddingTop: "56.25%",
    position: "relative",
    borderRadius: "9px",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  cardContainer: {
    background: "#FFF",
    borderRadius: "11px",
    boxShadow: "none",
    // borderTop: '1px solid rgba(102, 102, 102, 0.19)',
    marginBottom: "15px",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05)",
    // minHeight: '120px'
  },
  textStyle: {
    fontFamily: theme.palette.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "22px",
    color: "#000",
  },
  headingFont: {
    fontSize: "22px",
  },
  subHeadingFont: {
    fontSize: "18px",
    color: "#3F3F3F",
  },
  text: {
    fontSize: "14px",
    textAlign: "center",
  },
  progressBar: {
    backgroundColor: "#09A9BB",
  },
  bar: {
    backgroundColor: "#9B9B9B",
  },
}));
