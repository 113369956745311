import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Divider,
  useMediaQuery,
  Card,
  CardContent,
  DialogActions,
  Dialog,
  DialogContent,
  Button,
} from "@material-ui/core";

import { Link } from "gatsby";
import { LIVE_URL } from "../../utils/constants";
import groupChallengeIcon from "../../assets/img/homeFeatureIcons/groupChallengeIcon.svg";
import selfPracticeIcon from "../../assets/img/homeFeatureIcons/selfPracticeIcon.svg";
import TopCoursesIcon from "../../assets/img/homeFeatureIcons/TopCoursesIcon.svg";
import amazingFeatureMobile from "../../assets/img/amazingFeatureMobile.svg";
import amazingFeatureDesktop from "../../assets/img/amazingFeatureDesktop.svg";

const FeatureDescriptionCard = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Card
      style={{
        backgroundColor: "transparent",
        boxShadow: "none",
        borderBottom: "transparent",
      }}
    >
      <CardContent
        style={{
          padding: "15px 0px",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        <Grid container>
          <Grid
            item
            xs={3}
            md={2}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={props?.img}
              alt={"icons"}
              style={{ height: "70%", width: "70%" }}
              loading="lazy"
            />
          </Grid>

          <Grid item container xs={9} md={10} justify="flex-end">
            <Grid item xs={11}>
              <Typography
                className={classes.cardHeading}
                style={{ fontSize: isMobile && "24px" }}
              >
                {props.heading}
              </Typography>
            </Grid>

            <Grid
              item
              xs={11}
              style={{
                borderBottom: isMobile ? "#fff" : "1px solid #C2C2C2",
                paddingBottom: "10px",
              }}
            >
              <Typography
                className={classes.cardDescription}
                style={{ fontSize: isMobile && "18px" }}
              >
                {props.description}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
function NewSection({ data, strapiSiteIntro, strapiSiteInfo }) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const featureIcons = [TopCoursesIcon, groupChallengeIcon, selfPracticeIcon];
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Grid
      container
      className={classes.container}
      style={{
        padding: isMobile ? "0px 5px 20px 5px" : "20px 30px",
        background: isMobile && "#FFF",
      }}
    >
      <Grid item xs={12}>
        <Dialog
          // fullScreen={true}
          // fullWidth={false}
          minWidth={"sm"}
          open={open}
          onClose={handleClose}
          scroll={isMobile ? "body" : "paper"}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogContent style={{ backgroundColor: "#FFFFFF" }}>
            <Typography style={{ color: "#010000 " }}>
              Please go to{" "}
              <Link
                to={`${LIVE_URL}`}
                target="_blank"
                style={{ color: "#0E0AF3" }}
              >
                {LIVE_URL}
              </Link>{" "}
              and click on add to home screen button to install this app on your
              mobile.
            </Typography>
          </DialogContent>
          <DialogActions style={{ backgroundColor: "#FFFFFF" }}>
            <Button
              aria-label="Cancel"
              style={{ color: "#010000 " }}
              onClick={handleClose}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
      {/* {isMobile && ( */}
      <Grid item md={12} xs={12} sm={12}>
        <Grid item xs={12}>
          <Typography
            className={classes.heading}
            style={{
              fontSize: isMobile && "24px",
              lineHeight: "33px",
              padding: isMobile ? "10px 16px 10px 16px" : "0px 16px 0px 0px",
            }}
          >
            {strapiSiteIntro?.Title}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider className={classes.divider} />
        </Grid>
        <Grid item xs={12}>
          <Typography
            className={classes.subHeading}
            style={{
              fontSize: isMobile && "18px",
              padding: isMobile ? "0px 16px 0px 16px" : "0px 16px 0px 0px",
            }}
          >
            {strapiSiteIntro?.subtitile}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider className={classes.divider} />
        </Grid>

        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "25px",
          }}
        >
          <Link
            to={`https://play.google.com/store/apps/details?id=com.omid.life`}
            style={{
              textDecoration: "none",

              marginRight: "2%",
            }}
            target="_blank"
          >
            <img
              src={"/img/googlePlay.png"}
              alt="Google Play Store "
              height="50px"
              width="150px"
              style={{ cursor: "pointer" }}
              loading="lazy"
            />
          </Link>

          {/* <Link
            onClick={() => setOpen(true)}
            style={{ textDecoration: "none" }}
            target="_blank"
          > */}
          <img
            src={"/img/appStore.png"}
            alt="App Store "
            height="50px"
            width="150px"
            style={{ cursor: "pointer", borderRadius: "10px" }}
            onClick={() => setOpen(true)}
            loading="lazy"
          />
        </Grid>
      </Grid>
      {/* )} */}

      <Grid item md={7} xs={12} sm={12} container>
        <Grid item xs={12}>
          <Typography
            className={classes.heading}
            style={{
              fontSize: isMobile && "24px",
              textAlign: isMobile ? "center" : "left",
              padding: isMobile ? "0px 16px 0px 16px" : "0px 16px 0px 0px",
            }}
          >
            {strapiSiteInfo?.featureHeading}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography
            className={classes.subHeading}
            style={{
              textAlign: isMobile ? "center" : "left",
              padding: isMobile ? "0px 16px 0px 16px" : "0px 16px 0px 0px",
              fontSize: isMobile && "18px",
            }}
          >
            {strapiSiteInfo?.featureDescription}
          </Typography>
        </Grid>

        {isMobile && (
          <Grid
            item
            xs={12}
            // style={{
            //   minHeight: isMobile ? "280px" : "600px",
            //   backgroundImage: `url(/img/iphone.png)`,
            //   backgroundSize: "cover",
            //   backgroundRepeat: "no-repeat",
            // }}
          >
            <img
              src={amazingFeatureMobile}
              alt={"ss"}
              style={{ width: "100%", height: "100%" }}
              loading="lazy"
            />
          </Grid>
        )}

        <Grid item xs={12} container>
          {data?.edges?.map((item, index) => {
            return (
              <Grid item xs={12} container key={index}>
                {/* <Grid xs={1} item >
                <img
                  src={featureIcons[index]}
                  alt={"icons"}
                  style={{ width: "40%", height: "40%" }}
                />
              </Grid> */}
                <Grid item xs={12}>
                  <FeatureDescriptionCard
                    heading={item?.node?.title}
                    description={item?.node?.description}
                    img={featureIcons[index]}
                  />
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
      {!isMobile && (
        <Grid item md={5} xs={11} sm={11} container>
          <Grid
            item
            xs={12}
            // style={{
            //   minHeight: isMobile ? "280px" : "600px",
            //   backgroundImage: `url(/img/iphone.png)`,
            //   backgroundSize: "cover",
            //   backgroundRepeat: "no-repeat",
            // }}
          >
            <img
              src={amazingFeatureDesktop}
              alt={"ss"}
              style={{ width: "100%", height: "100%" }}
              loading="lazy"
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

export default NewSection;

const useStyles = makeStyles((theme) => ({
  container: {
    background: "#FBFBFB",

    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  heading: {
    fontFamily: theme.palette.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "32px",
    lineHeight: "56px",
    color: "#000",
    textAlign: "center",
    letterSpacing: "0.05em",
  },
  subHeading: {
    fontFamily: theme.palette.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "22px",

    color: "#3F3F3F",
    textAlign: "center",
    letterSpacing: "0.05em",
  },
  description: {
    fontFamily: theme.palette.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: "151.69%",
    color: "#153959",
    letterSpacing: "0.045em",
    textAlign: "center",
    marginTop: "15px",
    //     marginLeft: '18px',
    //     marginRight: '20px'
  },
  divider: {
    background: "#C2C2C2",
    height: "2px",
  },
  center: {
    minHeight: "400px",
    // width: '100%',
    display: "flex",
    alignItems: "center",
  },

  cardHeading: {
    fontFamily: theme.palette.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "32px",
    lineHeight: "30px",
    color: "#000000",
    paddingBottom: "7px",
  },
  cardDescription: {
    fontFamily: theme.palette.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "22px",
    lineHeight: "130%",
    color: "#3D3D3D",
    letterSpacing: "0.045em",
  },
}));
