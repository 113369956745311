import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player/lazy";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid, Typography, useMediaQuery, Button } from "@material-ui/core";
import { HighlightOffRounded } from "@material-ui/icons";
import LiveEventCard from "../omidTv/liveEventCard";
import { graphql, useStaticQuery, Link } from "gatsby";
import CircularProgress from "@material-ui/core/CircularProgress";
import clsx from "clsx";
import VisibilitySensor from "react-visibility-sensor";
// import IntroVideo from "../../../assets/video/OmidIntro.mp4";
const IntroVideo =
  process.env.REACT_APP_INTRO_VIDEO ||
  "https://ipfsn.omidlife.com/ipfs/Qmf8khwqMWZ1DCT4AMQinJ4TYQcrRXUD1zSFZKUCEGKKCA";
// import EventStartTimer from '../../common/eventStartTimer'
// import Countdown from 'react-countdown';

export default function OmidTv(props) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { results, resultsWithFilters } = useStaticQuery(query);
  const [currentEventUrl, setCurrentEventUrl] = useState("");
  // const [endDate, setEndDate] = useState(allStrapiOmidTvs?.edges?.length > 0 ? allStrapiOmidTvs?.edges[0]?.node?.endDate : '');
  const [currentEventId, setCurrentEventId] = useState("");
  const [mediaError, setMediaError] = useState("");
  const [playing, setPlaying] = useState(false);
  const [eventIndex, setEventIndex] = useState(0);
  const [mute, setMute] = useState(true);
  const [loader, setLoader] = useState(true);
  const [endDate, setEndDate] = useState("");
  const [introVideo, setIntroVideo] = useState(true);
  // const [isSessionTime, setIsSessionTime] = useState(false);
  const [allStrapiOmidTvs, setAllStrapiOmidTvs] = useState({ edges: [] });

  useEffect(() => {
    let filteredResults = [...resultsWithFilters?.edges].filter(
      (item) =>
        new Date() <= new Date(item?.node?.endDate) &&
        new Date() >= new Date(item?.node?.startDate)
    );
    let tvs = [...results?.edges, ...filteredResults].sort(
      (a, b) => a?.node?.order - b?.node?.order
    );
    setAllStrapiOmidTvs({ edges: tvs });
    setCurrentEventUrl(tvs && tvs[0]?.node?.Url);
    setCurrentEventId(tvs && tvs[0]?.node?.id);
    setEndDate(tvs && tvs[0]?.node?.isScheduled ? tvs[0]?.node?.endDate : "");
  }, [results?.edges, resultsWithFilters?.edges]);

  const handlePlayEvent = (event, ind) => {
    setIntroVideo(true);
    setLoader(true);
    setMute(false);
    setEventIndex(ind);
    setCurrentEventUrl(event?.node?.Url);
    setCurrentEventId(event?.node?.id);
    setEndDate(event?.node?.isScheduled ? event?.node?.endDate : "");
    setMediaError("");
  };

  const handleEnded = () => {
    if (!introVideo) {
      const newInd =
        eventIndex >= allStrapiOmidTvs?.edges?.length - 1 ? 0 : eventIndex + 1;
      setEventIndex(newInd);
      setCurrentEventUrl(allStrapiOmidTvs?.edges[newInd]?.node?.Url);
      setCurrentEventId(allStrapiOmidTvs?.edges[newInd]?.node?.id);
      setEndDate(
        allStrapiOmidTvs?.edges[newInd]?.node?.isScheduled
          ? allStrapiOmidTvs?.edges[newInd]?.node?.endDate
          : ""
      );
    }
    setMediaError("");
    setIntroVideo(!introVideo);
  };

  const onChange = (isVisible) => {
    isVisible ? setPlaying(true) : setPlaying(false);
  };

  return (
    <>
      {allStrapiOmidTvs?.edges?.length > 0 && (
        <Grid
          container
          justify="center"
          style={{ backgroundColor: isMobile ? "#FFF" : "#F5F5F5" }}
        >
          <Grid item xs={12}>
            <Typography
              className={isMobile ? classes.mobileHeading : classes.heading}
            >
              {props?.data?.omidTvTitle}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              className={
                isMobile ? classes.subHeadingMobile : classes.subHeading
              }
            >
              {props?.data?.omidTvDescription}
            </Typography>
          </Grid>

          <Grid
            container
            item
            xs={12}
            style={{
              padding: isMobile ? "20px 0px 0px 0px" : "20px 30px 0px 30px",
            }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              style={{ padding: "10px 0px" }}
            >
              <VisibilitySensor onChange={onChange}>
                <div className={classes.playerWrapper}>
                  {loader && (
                    <div
                      className={clsx(classes.reactPlayer, classes.preLoadBody)}
                    >
                      <CircularProgress style={{ color: "#09A9BB" }} />
                    </div>
                  )}
                  {mediaError ? (
                    <div
                      className={clsx(classes.reactPlayer, classes.preLoadBody)}
                    >
                      <HighlightOffRounded style={{ color: "red" }} />{" "}
                      <Typography
                        style={{ paddingTop: "3px", paddingLeft: "3px" }}
                      >
                        {mediaError}
                      </Typography>
                    </div>
                  ) : !endDate ? (
                    <ReactPlayer
                      controls={true}
                      className={classes.reactPlayer}
                      width="100%"
                      height="100%"
                      // onProgress={handleProgress}
                      onEnded={handleEnded}
                      // onStart={handleEpisodeStart}
                      // loop={true}
                      muted={true || mute}
                      playing={playing}
                      // light={sessionThumbnail}
                      onError={() => {
                        currentEventUrl &&
                          setMediaError(`Media Url is not exist/corrupted!`);
                        setLoader(false);
                      }}
                      // url={'https://i.vimeocdn.com/video/4603325990'}
                      url={introVideo ? IntroVideo : currentEventUrl}
                      config={{
                        youtube: {
                          playerVars: { showinfo: 1 },
                        },
                        file: {
                          attributes: {
                            preload: "none",
                            controlsList: "nodownload",
                          },
                        },
                      }}
                    />
                  ) : (
                    <ReactPlayer
                      controls={true}
                      className={classes.reactPlayer}
                      width="100%"
                      height="100%"
                      // onProgress={handleProgress}
                      onEnded={handleEnded}
                      // onStart={handleEpisodeStart}
                      // loop={true}
                      muted={true || mute}
                      playing={playing}
                      // light={sessionThumbnail}
                      onError={() => {
                        setLoader(false);
                      }}
                      // url={'https://i.vimeocdn.com/video/4603325990'}
                      url={introVideo ? IntroVideo : currentEventUrl}
                      config={{
                        youtube: {
                          playerVars: { showinfo: 1 },
                        },
                        file: {
                          attributes: {
                            preload: "none",
                          },
                        },
                      }}
                    />
                  )}
                </div>
              </VisibilitySensor>
              {/* // <div className={classes.playerWrapper}>
                            //     <div className={classes.reactPlayer}>
                                //         <Countdown date={new Date(endDate)} />
                            //     </div>
                            // </div>
                        // <EventStartTimer time={new Date(endDate)} onComplete={() => setIsSessionTime(true)} /> */}
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              style={{ padding: "10px 0px 0px 0px" }}
            >
              <div
                className={classes.playerWrapper}
                style={{ marginLeft: !isMobile && "30px" }}
              >
                <div
                  className={classes.reactPlayer}
                  style={{
                    height: "100%",
                    backgroundColor: isMobile ? "#fff" : "#F5F5F5",
                    overflowY: "auto",
                  }}
                >
                  {allStrapiOmidTvs?.edges?.map((event, ind) => (
                    <div
                      key={ind}
                      style={{ cursor: "pointer" }}
                      onClick={() => handlePlayEvent(event, ind)}
                    >
                      <LiveEventCard
                        id={currentEventId}
                        data={event?.node}
                        ind={ind + 1}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </Grid>

            <Grid
              item
              xs={8}
              sm={4}
              md={3}
              lg={2}
              xl={2}
              style={{ textAlign: "center", margin: "auto" }}
            >
              <Link to="/view-all" style={{ textDecoration: "none" }}>
                <Button aria-label="View all" className={classes.viewAllButton}>
                  View all
                </Button>
              </Link>
            </Grid>
          </Grid>
          {/* <StaticQuery
                    query={graphql`
              {
                allStrapiOmidTvs{
                    edges {
                      node {
                        id
                        Url
                        Title
                        Description
                        order

                      }
                    }
                  }
              }
            `}
                    render={data => {

                        return (
                            ''
                        )
                    }}
                /> */}
        </Grid>
      )}
    </>
  );
}

// export default IndexPage

export const query = graphql`
  {
    results: allStrapiOmidTvs(
      sort: { fields: order, order: ASC }
      filter: { isScheduled: { eq: false } }
    ) {
      edges {
        node {
          id
          Url
          Title
          Description
          order
          Thumbnail {
            publicURL
            childImageSharp {
              fluid(fit: INSIDE, quality: 10) {
                srcWebp
                sizes
              }
              fixed(fit: CONTAIN, quality: 10, webpQuality: 20, width: 120) {
                width
                srcWebp
              }
            }
          }
        }
      }
    }
    resultsWithFilters: allStrapiOmidTvs(
      sort: { fields: order, order: ASC }
      filter: { isScheduled: { eq: true } }
    ) {
      edges {
        node {
          id
          Url
          Title
          Description
          order
          isScheduled
          startDate
          endDate
          Thumbnail {
            publicURL
          }
        }
      }
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  heading: {
    fontFamily: theme.palette.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "32px",
    lineHeight: "49px",
    letterSpacing: "0.05em",
    textAlign: "center",
    color: "#000",
    paddingTop: "15px",
  },
  mobileHeading: {
    fontFamily: theme.palette.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "24px",
    lineHeight: "33px",
    letterSpacing: "0.05em",
    textAlign: "center",
    color: "#000",
    paddingTop: "15px",
  },
  subHeading: {
    fontFamily: theme.palette.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "22px",
    lineHeight: "36px",
    textAlign: "center",
    letterSpacing: "0.045em",
    color: "#3F3F3F",
  },
  subHeadingMobile: {
    fontFamily: theme.palette.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "18px",
    lineHeight: "130%",
    textAlign: "center",
    letterSpacing: "0.045em",
    color: "#3F3F3F",
  },
  playerWrapper: {
    position: "relative",
    paddingTop: "56.25%",
    height: 0,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundColor: "#a9a9a9",

    // paddingTop: `${(157/258)*100}%`
  },
  reactPlayer: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 2,
    // width: '100%'
  },
  viewAllButton: {
    color: theme.palette.secondary.light,
    margin: "20px 0px",
    textAlign: "center",
    border: "1px solid #FF6035",
    borderRadius: "22px",
    backgroundColor: "transparent",
    padding: "7px 40px",
    width: "100%",
  },
  preLoadBody: {
    display: "flex",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#a9a9a9",
  },
}));
