import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid, Typography, Button } from "@material-ui/core";

import { Link, useStaticQuery } from "gatsby";
import { LIVE_URL, PICTURE_STRAPI_API_URL } from "../../utils/constants";
import "./index.css";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import desktopWallpaperImg from "../../assets/img/1024x768/CONSCIOUS-LOVE.png";
import breathworkImg from "../../assets/img/1024x768/breathwork.png";
import consciousLivingImg from "../../assets/img/1024x768/CONSCIOUS-LIVING.png";
import meditationImg from "../../assets/img/1024x768/meditation.png";
import masterclassImg from "../../assets/img/1024x768/master-class.png";
import yoga from "../../assets/img/1024x768/yoga.png";
import { useMediaQuery } from "@material-ui/core";
import BackgroundSlider from "react-background-slider";
var intervals = "";

function HomeSection1({ title, image, baseLine, description, animatedText }) {
  const classes = useStyles();
  const theme = useTheme();
  const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
  const [imagesArray, setImagesArray] = useState([]);
  const [itemArray, setItemsArray] = useState([]);
  var txtIndex = 0;
  console.log(baseLine, "baseLine");
  const isMobile259 = useMediaQuery(`(max-width:281px)`);

  const data = useStaticQuery(graphql`
    query HomeMobileQuery {
      allStrapiHomeCarousels(sort: { fields: order }) {
        nodes {
          mobileImage {
            publicURL
            childrenImageSharp {
              fluid {
                srcWebp
              }
            }
          }
          order
          title
        }
      }
    }
  `);

  function intervalFunction(itm) {
    let intervals1 = setTimeout(function () {
      txtIndex = txtIndex + 1 < itm.length ? txtIndex + 1 : 0;

      var div = document.getElementById("text2");
      if (itm[txtIndex]) {
        div.innerHTML = itm[txtIndex];
      }
      // div.innerHTML = itm[txtIndex];
      clearInterval(intervals1);
      intervalFunction(itm);
    }, 4100);
  }

  useEffect(() => {
    try {
      let att = [];
      let itm = [];
      if (data?.allStrapiHomeCarousels?.nodes?.length > 0) {
        setImagesArray([]);
        data.allStrapiHomeCarousels.nodes.forEach((step, index) => {
          // att.push(`${step?.mobileImage?.publicURL}`);
          att.push(`${step.mobileImage.childrenImageSharp[0].fluid.srcWebp}`);
          itm.push(step?.title);
        });
      }
      setItemsArray([...itm]);
      setImagesArray(att);

      var div = document.getElementById("text2");
      div.innerHTML = itm[txtIndex];
      intervalFunction(itm);
    } catch (e) {}
  }, [data]);
  useEffect(() => {
    return () => clearInterval(intervals);
  }, []);

  console.log(intervalFunction, "intervalFunction");

  return (
    <>
      <div
        className={classes.root}
        style={{ width: "100%", position: "relative" }}
      >
        <Grid container className={classes.root}>
          <Grid
            item
            container
            xs={12}
            style={{
              zIndex: 10,
            }}
          >
            <Grid
              item
              container
              xs={12}
              direction="row"
              style={{
                height: 560,
                justifyContent: "space-between",
              }}
            >
              <Grid container style={{ flexGrow: 1 }}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  className={classes.titleContainer}
                  container
                  justify="center"
                  style={{ marginTop: "3%" }}
                >
                  <Typography
                    variant="h2"
                    className={classes.heading}
                    style={{
                      fontSize: isMobile259 ? "14px" : "18px",
                      paddingTop: "10px",
                    }}
                  >
                    {title}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container style={{ maxHeight: "100px" }}>
                <Grid item xs={12} sm={12} container>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    <ul
                      style={{
                        height: "40px",
                        padding: "0px",
                        display: "flex",
                        justifyContent: "center",
                        fontSize: isMobile259 ? "16px" : "23px",
                      }}
                    >
                      <h1
                        id="text2"
                        className={classes.items}
                        style={{
                          height: "40px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      ></h1>
                    </ul>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  container
                  className={classes.titleContainer}
                  style={{
                    textAlign: "center",
                    padding: "0px 10px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    variant="h2"
                    className={classes.heading}
                    style={{ fontSize: isMobile259 ? "14px" : "18px" }}
                  >
                    {baseLine}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {imagesArray.length > 0 && (
          <BackgroundSlider
            images={imagesArray}
            duration={4}
            transition={0}
            // style={{ postion: "absolute" }}
          />
        )}
      </div>
      <Grid
        item
        container
        xs={12}
        className={classes.centerBtnContainer}
        justify="center"
        style={{ zIndex: 100 }}
      >
        <Grid
          item
          xs={6}
          sm={5}
          style={{ textAlign: "center", minWidth: "150px", zIndex: "inherit" }}
        >
          <Link
            to={`${LIVE_URL}auth?tab=signup`}
            style={{ textDecoration: "none" }}
          >
            <Button
              aria-label="Lets Start"
              variant="contained"
              className={classes.letsStartBtn}
              style={{
                minWidth: "110px",
              }}
            >
              Lets Start
            </Button>
          </Link>
        </Grid>

        <Grid
          item
          xs={6}
          sm={5}
          style={{ textAlign: "center", zIndex: "inherit" }}
        >
          <Link
            to={`${LIVE_URL}auth`}
            style={{ textDecoration: "none" }}
            // target="_blank"
          >
            <Button
              variant="contained"
              aria-label="Sign In"
              className={classes.letsStartBtn}
              style={{
                minWidth: "110px",
              }}
            >
              Sign In
            </Button>
          </Link>
        </Grid>
      </Grid>
    </>
    // </AutoPlaySwipeableViews>
  );
}

export default HomeSection1;
const useStyles = makeStyles((theme) => ({
  items: {
    position: "relative",
    top: 0,
    animation: "move 5s infinite",
    animationDuration: "6s",
    animationTimingFunction: "ease",
    animationDelay: "0s",
    animationIterationCount: "infinite",
    animationDirection: "normal",
    animationFillMode: "none",
    animationPlayState: "running",
    fontFamily: theme.palette.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "23px",
    lineHeight: "49px",
    textAlign: "center",
    letterSpacing: " 0.01em",
    // color: "#FFFFFF",
    color: "#FF6035",
    // marginTop: "190px",
    // fontFamily: "Myriad Pro",

    // marginBottom: "20px",
  },
  itemsXs: {
    position: "relative",
    top: 0,
    animation: "move 5s infinite",
    animationDuration: "6s",
    animationTimingFunction: "ease",
    animationDelay: "0s",
    animationIterationCount: "infinite",
    animationDirection: "normal",
    animationFillMode: "none",
    animationPlayState: "running",
    fontFamily: theme.palette.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "23px",
    lineHeight: "49px",
    textAlign: "center",
    letterSpacing: " 0.01em",
    // color: "#FFFFFF",
    color: "#FF6035",
    marginTop: "120px",
    // fontFamily: "Myriad Pro",
  },
  root: {
    // minHeight: "80vh",

    // backgroundImage: `url(${backgroundImage})`,
    // background: `#C4C4C4`,
    backgroundAttachment: "scroll",
    // imheight: 0,
    // position:'fixed',
    // bottom:0,
    // paddingTop: "56.25%",
    // position: "relative",
    // padding: '40px 10px',
    // backgroundSize: "cover",
    backgroundSize: "contain",

    backgroundRepeat: "no-repeat",
    // backgroundPosition: "center",
    display: "flex",
    alignItems: "center",
    height: 400,
  },
  centerBtnContainer: {
    textAlign: "center",
    // margin: "auto",
  },
  centerContainer: {
    textAlign: "center",
    margin: "auto",
    // width: '92px',
    // height: ' 94px',
    // marginTop: '90%',
  },
  titleContainer: {
    textAlign: "center",
    maxWidth: "100%",
    marginLeft: "0px",
    marginRight: "0px",
  },
  heading: {
    fontStyle: "normal",
    // fontWeight: 900,
    fontSize: "21px",
    // marginTop: "8%",
    lineHeight: "25px",
    textAlign: "center",
    fontFamily: theme.palette.fontFamily.Reef,
    // color: "#FFFFFF",
    // fontFamily: "Myriad Pro",

    paddingLeft: "10px",
    paddingRight: "10px",
    color: "black",
  },
  letsStartBtn: {
    textTransform: "none",
    width: "95%",
    // height: "49px",
    borderRadius: "59px",

    fontFamily: theme.palette.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "22px",
    lineHeight: "30px",
    letterSpacing: " 0.09em",
    color: "#FFFFFF",
    // marginTop: "15px",
    transition: "background 1s",
    // background: theme.palette.secondary.dimLight,
    background: theme.palette.secondary.light,
    border: "none",
    "&:hover": {
      // background: theme.palette.secondary.dimLight,
      background: theme.palette.secondary.light,

      border: "none",
    },
  },
  subHeading: {
    fontFamily: theme.palette.fontFamily.Reef,
    // marginTop: "10px",
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "16px",
    lineHeight: "25px",
    // textAlign: "center",
    letterSpacing: " 0.02em",
    // color: "#FFFFFF",
    color: "black",
  },

  signInBtn: {
    textTransform: "none",
    width: "95%",
    height: "49px",

    borderRadius: "59px",
    fontFamily: theme.palette.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "22px",
    lineHeight: "30px",
    letterSpacing: " 0.09em",
    color: "#FFFFFF",
    // marginTop: "30px",
    transition: "background 1s",
    background: "transparent",
    border: "2px solid #FFFFFF",
    "&:hover": {
      background: theme.palette.secondary.light,
      border: "2px solid transparent",
    },
  },
  header: {
    display: "flex",
    alignItems: "center",
    // height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
  },
  img: {
    height: 250,
    display: "block",
    // maxWidth: 400,
    overflow: "hidden",
    // width: "100%",
  },
}));
